import React from "react";

import "./styles.scss";

const WhyAttendHasuraCon = () => (
  <section className="sectionWrapper removePaddTop">
    <div className="cocWrapper">
      <h1 className="title">Why Attend HasuraCon?</h1>
      <div className="articleDesc">
        HasuraCon is a conference about you, your success, and...of course…full of deep-dive
        technical content. We are bringing together users from all over the world for a 2 day
        celebration of all things Hasura!
        <br />
        <br />
        Every HasuraCon is made up of product details, roadmaps, user stories, deep dives, and Q&A.
        Whether you are just exploring Hasura for the first time or optimising the architecture of
        your applications, you will hear what’s coming and pick up the tips and tricks you need to
        make your data accessible and ship applications faster.
        <br />
        <br />
        But who is it for? And why should you attend?
      </div>
      <h2 className="articleTitle">Who Should Attend?</h2>
      <div className="articleDesc">
        The conference has content that ranges from live, interactive, in-depth workshops to stories
        of people building products with Hasura to the Hasura team sharing our roadmap to deep-dives
        into the tech itself.
        <br />
        <br />
        This is perfectly suited for:
        <br />
        &nbsp;&#8226;&nbsp; Indie Developers
        <br />
        &nbsp;&#8226;&nbsp; Developer teams
        <br />
        &nbsp;&#8226;&nbsp; Technical Management
        <br />
        &nbsp;&#8226;&nbsp; Software Architects
        <br />
        &nbsp;&#8226;&nbsp; GraphQL Experts and Beginners
        <br />
        <br />
        Also, we have a workshop that is targeted to the beginner to intermediate Haskell developer!
      </div>
      <h2 className="articleTitle">Why Should I Attend?</h2>
      <div className="articleDesc">
        <b>A Glimpse Into The Future -</b> The way that we build software is changing. Hasura
        automates data access so developers can build mission-critical applications faster. Join
        HasuraCon ‘21 to discover just what the future of making data access “self-serve” looks
        like.
        <br />
        <br />
        If you are a Hasura user today, you know the benefits. Your glimpse into the future comes in
        the form of some special product announcements and feature deep dives.
        <br />
        <br />
        <b>Content Just For You -</b> Keynotes featuring the Hasura founders and special guests
        (including Guillermo Rauch, CEO Vercel), industry analysts providing their perspective on
        the changing landscape of software development and application deployment, deep dive into
        current and upcoming features, user stories, and more. Regardless of who you are, HasuraCon
        is packed with content you will find valuable.
        <br />
        <br />
        And, of course, some very special product announcements.
        <br />
        <br />
        <b>Discover the Magical Developer Experience -</b> Developers love Hasura for the
        experience. Architects love Hasura because of what it enables. Executives love Hasura
        because of the impact on TCO and time-to-market. In addition to the sessions, we have 3
        special hands-on workshops that enable you to discover Hasura for the first time, explore
        data federation in context of Hasura and GraphQL, and -- if you are a Haskell developer --
        learn how to contribute to a quickly growing OSS project immediately.
        <br />
        <br />
        <b>Learn How To ‘Think Hasura’ -</b> Your legacy architecture will change. In fact, it is
        probably changing now (whether you know it or not). HasuraCon provides you the opportunity
        to gain a valuable perspective on data-access (via GraphQL or otherwise) while exploring the
        incremental migration of legacy architectures into modern data-access solutions.
        <br />
        <br />
        <b>Community Building and Engagement -</b> Hasura is powered by a passionate team and
        delights in the engagement of the community. If you are active in the community today...you
        will have an opportunity to directly interact with your peers (both inside and outside)
        Hasura during sessions and topic tables. If you are exploring whether Hasura specifically
        (or GraphQL broadly) are appropriate for your next step...there is no better place to start.
        <br />
        <br />
        Hope to see you there!
      </div>
    </div>
  </section>
);

export default WhyAttendHasuraCon;
