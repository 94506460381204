import React from "react";

import { HasuraConSEO } from "../../../components/hasuracon2021/HasuraConSEO";
import Layout from "../../../components/hasuracon2021/Layout";
import WhyAttendHasuraCon from "../../../components/hasuracon2021/WhyAttend";

const canonicalUrl = "https://hasura.io/events/hasura-con-2021/why-attend/";

const WhyAttend = props => (
  <Layout location={props.location}>
    <HasuraConSEO canonicalLink={canonicalUrl} />
    <WhyAttendHasuraCon />
  </Layout>
);

export default WhyAttend;
